import styled from 'styled-components';
import BgImage from 'common/src/assets/image/hosting/Background/background.png';
const Section2Wrapper = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;
  display: block;
  background: rgba(30, 33, 64, 0.8);
  background-image: url(${BgImage});
  //   background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  border-radius: 0px;
  width: 100%;
  position: relative;
  color: #fff;

  .fullname {
    font-size: 2rem;
  }

  .boxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .host_img {
    border-radius: 50%;
    width: 133px;
    height: 133px;
    border: 5px solid white;
  }
  .image_holder {
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
    }
  }
  .margin_right {
    margin-right: 25px;
  }
  .margin_left {
    margin-left: 25px;
  }
  .left_content {
    p {
      text-align: right;
    }
    .name {
      font-size: 22px;
      margin: 0;
    }
    .linkedin {
      margin-top: 8px;
      margin-bottom: 0px;
      a {
        text-decoration: none;
        color: #a9a9a9;
      }
    }
    .description {
      font-size: 14px;
      text-align: center;
    }
  }
  .right_content {
    p {
      text-align: left;
    }
    .name {
      font-size: 22px;
      margin: 0;
    }
    .linkedin {
      margin-top: 8px;
      margin-bottom: 0px;
      a {
        text-decoration: none;
        color: #a9a9a9;
      }
    }
    .description {
      font-size: 16px;
    }
  }
`;

export default Section2Wrapper;
